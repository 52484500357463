import styled from "styled-components";

export const MainApp = styled.main`
   body {
      margin: 0;
      font-family: 'Montserrat', sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
   }
   h2.question-text{
      font-size: clamp(1.4rem, 6vw, 1.4rem);
   }
   button{
      font-size: 0.8rem;
   }
   @media screen and (min-width: 980px){
      place-items: center;
   }
   .container{
      max-width: 700px;
   }
   h4{
      font-size: 1rem;
   }
`