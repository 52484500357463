import React from 'react'
import finish from '../finish.png';

const ShowMessage = ({ avg, score }) => {
   let message = {
      text: '',
      emoji: ''
   }

   if (avg >= 80) {
      message.text = 'Excellent Work!';
      message.emoji = '🎉';
   }
   else if (avg >= 70) {
      message.text = 'Good Work!';
      message.emoji = '🥂'
   }
   else {
      message.text = 'Keep Practicing';
      message.emoji = '💪'
   }

   return (
      <div className='container'>
         <div style={{marginLeft: 'auto', marginRight: 'auto', alignItems: 'center', display: 'flex'}}>
            <img src={finish} style={{height: 200, marginLeft: 'auto', marginRight: 'auto'}}/>
         </div>
         <p>Доорх хэсгээс та байгаль дэлхийдээ  нэг жилийн хугацаанд хэр их хүлэмжийн хий ялгаруулах үйлдэл хийж буй тухай датаг харна уу. </p>
         <br/>
         <h4 style={{textAlign: "justify"}}>Та нэг жилд дунджаар {score} тонн нүүрсхүчлийн хийг амьдарч буй орчин, дадал зуршил, үйл ажиллагаанаасаа бий болгож байна.  </h4>
         <br/>
         <h4 style={{textAlign: "justify"}}>Монгол Улсын нэг хүнд ногдох хүлэмжийн хийн үзүүлэлт 2020 оны байдлаар дунджаар 26.9 тонн байна. </h4>
         <br/>
         <h4 style={{textAlign: "justify"}}>БНХАУ-ын улсын хувьд нэг хүнд ногдох хүлэмжийн хийн үзүүлэлт дунджаар 8.7 тонн байна.  </h4>
         <br/>
         <h4 style={{textAlign: "justify"}}>АНУ-ын улсын хувьд нэг хүнд ногдох хүлэмжийн хийн үзүүлэлт дунджаар 19.6 тонн байна. </h4>
         {/* <br/> */}
         {/* <h4 style={{textAlign: "justify"}}>Үхрийн мах идэх нь (хамгийн их CO2 ялгаруулсан хэсгийг оруулах) таны экологийн ул мөрөөс хамгийн их хүлэмжийн хий ялгаруулж буй дадалд тооцогдож байна. </h4> */}
         <br/>
         <h4 style={{textAlign: "justify"}}>Та өөрийн өдөр тутамд амьдралд гаргаж буй хүлэмжийн хийн ялгаруулалтаа бууруулахын тулд жилд {score * 40} ширхэг мод тарих шаардлагатай.</h4>
         <br/>

      </div>
   )
}

export default ShowMessage
