export const theme = {
   primary: "#A1BB7D",
   secondary: "#364323",
   success: "#364323",
   error: "#e93574",
   gradient: ["#7f00ff", "#e100ff"],
   textDark1: "#ffffff",
   text3: "#cccccc",
   text2: "#ebebeb",
   text1: "#000000",
   warning: "#fbc72c",
   surface1: "#F6F6EA",
   surface2: '#2a165b',
   surface3: '#361f71',
   borderRadius: '14px',
   transition: 'all ease 0.25s',
   boxShadow: '0 8px 30px rgb(0 0 0 / 15%);',
   padding: '8pt calc(16pt + 0.25rem)'
}