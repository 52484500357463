import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { NumericFormat } from 'react-number-format';
import { randQustions } from '../lib/quiz'
import ShowMessage from './PageElements/ShowMessage';
import { Box, Button } from './PageElements/UIElements';
import ShowConfetti from './ShowConfetti';
import logo from './logo.png';
import unlogo from './unlogo.png';

const BasicGrid = styled.div`
   display: grid;
   gap: 1rem;
   margin: 1rem 0;
`

const InputNum = styled.div`
   display: flex;
   justify-content: 'space-between';
   height: 40px;
   button:active{
      transform: scale(.9);
   }
`

function usePrevious(value) {
   const ref = useRef(0);
   useEffect(() => {
     ref.current = value;
   });
   return ref.current;
 }

const InputNumber = ({increment, handleInputChange, answer}) => {
   const [value, setValue] = useState(0);
   const prevValue = usePrevious(value);

   useEffect(() => {
      if(prevValue !== value){
         handleInputChange(answer, value-prevValue);
      }
   }, [value])

   const handleSubtract = () => {
      setValue(value - increment);
   }

   const handleAdd = () => {
      setValue(value + increment);
   }

   return(
      <InputNum>
      <button
         onClick={handleSubtract}
         disabled={value === 0}
         style={{background: '#A1BB7D', borderRadius: '40px', width: '40px', marginRight: '20px', display: 'flex', justifyContent: 'center', alignItems:'center', border: 'none'}}>
         <p style={{fontSize: '2rem', color: '#000'}}>-</p>
      </button>
      <NumericFormat 
         size="1"
         style={{flex: 1, height: '40px', textAlign: 'center'}} 
         value={value} 
         prefix={increment === 10000 ? '₮' : ''} 
         allowNegative={false} 
         thousandSeparator={true}
         defaultValue={0}
         />
      <button 
         onClick={handleAdd}
         style={{background: '#A1BB7D', borderRadius: '40px', width: '40px', marginLeft: '20px', display: 'flex', justifyContent: 'center', alignItems:'center', border: 'none'}}>
         <p style={{fontSize: '2rem', color: '#000'}}>+</p>
      </button>
   </InputNum>
   )
}

const QuestionCard = () => {
   const [currentIndex, setCurrentIndex] = useState(0)
   const [page, setPage] = useState(1)
   const [currentAnswerIndex, setCurrentAnswerIndex] = useState(0)
   const [endQuiz, setEndQuiz] = useState(false)
   const [score, setScore] = useState(0);
   const [avg, setAvg] = useState(0);
   const [value, setValue] = useState(0);
   const [showAns, setShowAns] = useState(false);
   const [selectedAns, setSelectedAns] = useState([]);
   const [currentQuestion, setCurrentQuestion] = useState(randQustions[0]);
   const [lastAdded, setLastAdded] = useState(0);
   //function to calc score and show correct answer
   const handleAnswerClick = (question, answerText, e) => {
      if(currentQuestion.isSingle) {
         let last = 0;
         if(selectedAns.length === 1) {
            if(selectedAns[0] === 'Үгүй')
               last = 0.6
            else
               last = lastAdded
         }
         setSelectedAns([answerText]);
         if(question === 'Танай гэрийн цахилгаан сэргээгдэх эрчим хүчний эх үүсвэртэй юу?' && answerText === 'Үгүй'){
            setScore((prev) => prev + 0.6);
            setLastAdded(0.6)
         }
         else if(question === 'Танай гэрийн цахилгаан сэргээгдэх эрчим хүчний эх үүсвэртэй юу?' && answerText === 'Тийм')
            setScore((prev) => prev - last);
         else if(question === 'Та гэрийнхээ цахилгааны төлбөрт сард дунджаар хэдэн төгрөг төлдөг вэ?') {
            if(answerText === '₮30,000-50,000') {
               setScore((prev) => prev + 2.6 - last);
               setLastAdded(2.6)
            }
            else if(answerText === '₮50,000-70,000') {
               setScore((prev) => prev + 3 - last);
               setLastAdded(3)
            }
            else if(answerText === '₮70,000-100,000') {
               setScore((prev) => prev + 4 - last);
               setLastAdded(4)
            }
            else if(answerText === '₮100,000 - дээш') {
               setScore((prev) => prev + 5 - last);
               setLastAdded(5)
            }
         }
      }
      else {
         if(selectedAns.includes(answerText)) {
            setSelectedAns(selectedAns.filter((item) => item !== answerText))
         } else {
            setSelectedAns([...selectedAns, answerText])
         }
      }
   }

   const handleInputChange = (answer, num) => {
      console.log(answer,num);
      if(currentQuestion.question.includes('Долоо хоногт хэр их тээврээр явдаг вэ ?')){
         setScore((prev) => prev + num * 0.4);
      }
      else if(currentQuestion.question === 'Та сүүлийн нэг жилд онгоцоор хэр их зорчсон бэ? (ирж буцахын хамт)' && answer === 'Дөрвөн цагаас бага') {
         setScore((prev) => prev + num * 0.6);
      }
      else if(currentQuestion.question === 'Та сүүлийн нэг жилд онгоцоор хэр их зорчсон бэ? (ирж буцахын хамт)' && answer === '4-8 цаг') {
         setScore((prev) => prev + num * 2.1);
      }
      else if(currentQuestion.question === 'Та сүүлийн нэг жилд онгоцоор хэр их зорчсон бэ? (ирж буцахын хамт)' && answer === '8-12 цаг') {
         setScore((prev) => prev + num * 3.5);
      }
      else if(currentQuestion.question === 'Та сүүлийн нэг жилд онгоцоор хэр их зорчсон бэ? (ирж буцахын хамт)' && answer === '12 цагаас их') {
         setScore((prev) => prev + num * 4.2);
      }
      else if(currentQuestion.question.includes('мах иддэг бол хэр их иддэг вэ?')) {
         setScore((prev) => prev + num * 0.4);
      }
      else if(currentQuestion.question === 'Сүү, сүүн бүтээгдэхүүн хэр их хэрэглэдэг вэ?') {
         setScore((prev) => prev + num * 0.1);
      }
      else if(currentQuestion.question === 'Та хувцас хэрэглэлд сард хэр их зардал гаргадаг вэ?') {
         setScore((prev) => prev + Math.floor(num / 10000) * 0.1);
      }
      else if(currentQuestion.question === 'Ахуйн хэрэглээний эд зүйлсэд сард хэр их зардал гаргадаг вэ?') {
         setScore((prev) => prev + Math.floor(num / 10000) * 0.1);
      }
      else if(currentQuestion.question === 'Та сүүлийн нэг жилд  электрон хэрэгсэл худалдан авахад хэр их мөнгө зарцуулсан бэ?') {
         setScore((prev) => prev + Math.floor(num / 10000) * 0.1);
      }
      else if(currentQuestion.question === 'Сүүлийн нэг жилд гэр ахуйн тавилга, эд хэрэгсэл худалдан авахад хэр их мөнгө зарцуулсан бэ?') {
         setScore((prev) => prev + Math.floor(num / 10000) * 0.1);
      }
   }

   //function to go to next question if within bounds of quiz length and hide correct answer 
   // if ouside the bounds of the quiz array, the quiz will end
   const nextQuestion = () => {
      setShowAns(false)
      setLastAdded(0)
      if (currentIndex === randQustions.length - 1) {
         endOfQuiz()
      }
      else {
         let selecTedanswers = selectedAns.filter(x => x !== 'Унадаг дугуй' && x !== 'Аль нь ч биш' && x !== 'Сүү сүүн бүтээгдэхүүн' && x !== 'Өндөг' && x !== 'Цагаан хоолтон')
         if(selecTedanswers.length > 0) {
            if(currentQuestion.question === 'Та ямар тээврийн хэрэгсэл хэрэглэдэг вэ? (олон сонголттой)' || currentQuestion.question.includes('Долоо хоногт хэр их тээврээр явдаг вэ ?')){
               if(currentAnswerIndex === selecTedanswers.length) {
                  setCurrentIndex(prev => prev + 1)
                  setCurrentQuestion(randQustions[currentIndex + 1])
                  setSelectedAns([])
                  setCurrentAnswerIndex(0)
                  setPage((prev) => prev + 1);
               }
               else {
                  let meat = selectedAns[currentAnswerIndex]
                  if(meat === 'Автомашин' || meat === 'Мотоцикл' || meat === 'Галт тэрэг' || meat === 'Автобус' || meat === 'Скүүтер') {
                     setCurrentQuestion(   
                        {
                           question: `Долоо хоногт хэр их тээврээр явдаг вэ ? (${selectedAns[currentAnswerIndex]})`,
                           answers: [
                              { answerText: "Долоо хоногт … удаа", isCorrect: true, isNumber: true },
                           ]
                        }
                     )
                     setCurrentAnswerIndex(prev => prev + 1)
                     setPage((prev) => prev + 1);
                  }
               }
            }
            else if(currentQuestion.question === 'Та ямар төрлийн хоол хүнс хэрэглэдэг вэ? (олон сонголттой)' || currentQuestion.question.includes('мах иддэг бол хэр их иддэг вэ?')){
               if(currentAnswerIndex === selecTedanswers.length) {
                  setCurrentIndex(prev => prev + 1)
                  setCurrentQuestion(randQustions[currentIndex + 1])
                  setSelectedAns([])
                  setCurrentAnswerIndex(0)
                  setPage((prev) => prev + 1);
               }
               else {
                  let meat = selectedAns[currentAnswerIndex]
                  if(meat === 'Үхэр' || meat === 'Хонь' || meat === 'Тахиа' || meat === 'Гахай' || meat === 'Загас') {
                     let meatText = ''
                     switch(meat){
                        case 'Үхэр':
                           meatText = 'үхрийн';
                           break;
                        case 'Тахиа':
                           meatText = 'тахиан';
                           break;
                        case 'Хонь':
                           meatText = 'хонины';
                           break;
                        case 'Гахай':
                           meatText = 'гахайн';
                           break;
                        case 'Загас':
                           meatText = 'загасны';
                           break;
                        default:
                           break;   
                     }
                     setCurrentQuestion(   
                        {
                           question: `Хэрэв ${meatText} мах иддэг бол хэр их иддэг вэ?`,
                           answers: [
                              { answerText: "Долоо хоногт … удаа", isCorrect: true, isNumber: true },
                           ]
                        }
                     )
                     setCurrentAnswerIndex(prev => prev + 1)
                     setPage((prev) => prev + 1);
                  }
                  else {
                     setCurrentIndex(prev => prev + 1)
                     setCurrentQuestion(randQustions[currentIndex + 1])
                     setSelectedAns([])
                     setCurrentAnswerIndex(0)
                     setPage((prev) => prev + 1);
                  }

               }
            }
            else {
               setCurrentIndex(prev => prev + 1)
               setCurrentQuestion(randQustions[currentIndex + 1])
               setSelectedAns([])
               setCurrentAnswerIndex(0)
               setPage((prev) => prev + 1);
            }
         }
         else { 
            setCurrentIndex(prev => prev + 1)
            setCurrentQuestion(randQustions[currentIndex + 1])
            setSelectedAns([])
            setCurrentAnswerIndex(0)
            setPage((prev) => prev + 1);

         }
      }
      setValue(0);
   }

   //function to pre-emptively end quiz
   const seeResults = () => {
      setEndQuiz(true)
      setAvg((score / currentIndex) * 100)
   }

   const endOfQuiz = () => {
      setEndQuiz(true)
      setCurrentIndex(prev => prev + 1)
      window.scrollTo(0, 0)
      setAvg((score / currentIndex) * 100)
   }

   //function to result all variables 
   const reset = () => {
      setEndQuiz(false);
      setCurrentIndex(0);
      setCurrentQuestion(randQustions[0]);
      setScore(0)
      setShowAns(false)
      setPage(1);
   }
   if (endQuiz) {
      return (
         <>
            {/* {avg >= 80 &&
               <ShowConfetti whenToShow={endQuiz} />
            } */}
            <Box >
               <div className="spacer"></div>
               <div className="top" style={{ textAlign: 'center' }}>
                  <ShowMessage avg={avg} score={score.toFixed(2)} />
               </div>
               <Button onClick={reset} >Баярлалаа, Дахин тооцоолох уу ?</Button>
               <br/>
               <br/>
            </Box>

         </>
      )
   }

   return (
      <div className='container'>
         <div style={{marginLeft: 'auto', marginRight: 'auto', alignItems: 'center', display: 'flex'}}>
            <img src={unlogo} style={{height: 150, marginLeft: 'auto'}}/>
            <img src={logo} style={{height: 150, marginRight: 'auto'}}/>
         </div>
            <p>Хүлэмжийн хийн ялгаруулалт: {score.toFixed(2)} тонн</p>
            <br/>
         <Box className='question-box m-full-y' >
            <div className="top">
               {/* <h6 className='top-text'>Асуулт {currentIndex + 1} of {randQustions.length}</h6> */}
               <div className="question">
                  <h2 className='question-text'>{page + '. ' + currentQuestion.question}</h2>
               </div>
            </div>

            <BasicGrid className="answers-row middle">
               {currentQuestion.answers.map((answer, key) => (
                  <div key={key}>
                     <Button
                        className={selectedAns.includes(answer.answerText) ? 'ans' : ''}
                        onClick={(e) => { selectedAns.includes(answer.answerText) ? console.log() : handleAnswerClick(currentQuestion.question, answer.answerText, e) }}
                        key={key}
                        disabled={answer.isNumber}
                     >
                        {answer.answerText}
                     </Button>
                     <div className='mt-3'/>
                     {answer.isNumber &&
                     <InputNumber answer={answer.answerText} key={'answer' + currentIndex + currentAnswerIndex} increment={answer.isCurrency ? 10000 : 1} handleInputChange={handleInputChange}/>
                     }
                  </div>
               ))}
            </BasicGrid>

            <div className="bottom">
               <Button
                  style={{background: '#418FDE', color: '#FFFFFF'}}
                  onClick={nextQuestion}
                  >
                  Дараагийн асуулт
               </Button>
            </div>
         </Box>
      </div>
   )
}

export default QuestionCard
