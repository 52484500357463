import './App.css';
import React, { useState } from 'react';
import QuestionCard from './components/QuestionCard';
import { MainApp } from './components/PageElements/MainApp';
import { theme } from './data/AppTheme'
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-74N8EPY13M');

const GlobalStyle = createGlobalStyle`
  body{
    background: ${({ theme }) => theme.surface1};
    color: ${({ theme }) => theme.text1};
    font-size: clamp(1rem, 8vw, 1.3rem);
    > * {
      transition: 0.25s ease all;
    }
  }
  h1{
    font-size: clamp(1.2rem, 8vw, 2.8rem);
  }
  .title{
    font-size: 1rem
  }
  .title-text{
    font-size: 1rem
  }
`
function App() {
  
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <MainApp className="app">
        <QuestionCard />
        <p style={{ fontSize: '.7rem', textAlign: 'center', marginTop: 'auto', marginBottom: '40px'}}>Энэхүү тооцооллыг Дэлхийн Банкны Монгол Улсын уур амьсгалын өөрчлөлтийн талаарх тооцоо судалгаа, тайланд үндэслэн гаргав.</p>
      </MainApp>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='title'>“НҮБ-ын Залуу төлөөлөгч - Байгаль орчны манлайлал" хөтөлбөрийн хүрээнд хэрэгжүүлж буй “Хэмжээд Хас” төслийн хэрэгжүүлж буй хүлэмжийн хийн ялгаруулалтыг тооцоолох вэб хуудас </Modal.Title>
        </Modal.Header>
        <Modal.Body className='title-text'>Энэхүү төсөл нь уур амьсгалын өөрчлөлтийн үр нөлөөг таниулах, байгаль орчноо хамгаалж тогтвортой хөгжлийг цогцлооход иргэдийн оролцоог нэмэгдүүлэх зорилготой.</Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleClose}>
            Ок
          </Button>
        </Modal.Footer>
      </Modal>
    </ThemeProvider>
  );
}

export default App;
