

const questions = [
   {
      question: "Та ямар тээврийн хэрэгсэл хэрэглэдэг вэ? (олон сонголттой)",
      answers: [
         { answerText: "Автомашин", isCorrect: true },
         { answerText: "Мотоцикл", isCorrect: true },
         { answerText: "Галт тэрэг", isCorrect: true },
         { answerText: "Автобус", isCorrect: true },
         { answerText: "Скүүтер", isCorrect: true },
         { answerText: "Унадаг дугуй", isCorrect: true },
         { answerText: "Аль нь ч биш", isCorrect: true },
      ]
   },
   {
      question: "Та сүүлийн нэг жилд онгоцоор хэр их зорчсон бэ? (ирж буцахын хамт)",
      answers: [
         { answerText: "Дөрвөн цагаас бага", isCorrect: true, isNumber: true },
         { answerText: "4-8 цаг", isCorrect: true, isNumber: true },
         { answerText: "8-12 цаг", isCorrect: true, isNumber: true },
         { answerText: "12 цагаас их", isCorrect: true, isNumber: true },
      ]
   },
   {
      question: "Та ямар төрлийн хоол хүнс хэрэглэдэг вэ? (олон сонголттой)",
      answers: [
         { answerText: "Үхэр", isCorrect: true },
         { answerText: "Хонь", isCorrect: true },
         { answerText: "Тахиа", isCorrect: true },
         { answerText: "Гахай", isCorrect: true },
         { answerText: "Загас", isCorrect: true },
         { answerText: "Сүү сүүн бүтээгдэхүүн", isCorrect: true },
         { answerText: "Өндөг", isCorrect: true },
         { answerText: "Цагаан хоолтон", isCorrect: true },
      ]
   },
   {
      question: "Сүү, сүүн бүтээгдэхүүн хэр их хэрэглэдэг вэ?",
      answers: [
         { answerText: "Долоо хоногт … удаа  (тоо нэмэх)", isCorrect: true, isNumber: true },
      ]
   },
   {
      question: "Та хувцас хэрэглэлд сард хэр их зардал гаргадаг вэ?",
      answers: [
         { answerText: "Сард … төгрөг зарцуулдаг (тоо нэмэх)", isCorrect: true, isNumber: true, isCurrency: true },
      ]
   },
   {
      question: "Ахуйн хэрэглээний эд зүйлсэд сард хэр их зардал гаргадаг вэ?",
      answers: [
         { answerText: "Сард … төгрөг зарцуулдаг (тоо нэмэх)", isCorrect: true, isNumber: true, isCurrency: true },
      ]
   },
   {
      question: "Та сүүлийн нэг жилд  электрон хэрэгсэл худалдан авахад хэр их мөнгө зарцуулсан бэ?",
      answers: [
         { answerText: "Нэг жилд …. төгрөг зарцуулсан (тоо нэмэх)", isCorrect: true, isNumber: true, isCurrency: true },
      ]
   },
   {
      question: "Сүүлийн нэг жилд гэр ахуйн тавилга, эд хэрэгсэл худалдан авахад хэр их мөнгө зарцуулсан бэ?",
      answers: [
         { answerText: "Нэг жилд … төгрөг зарцуулсан (тоо нэмэх)", isCorrect: true, isNumber: true, isCurrency: true },
      ]
   },
   {
      question: "Танай гэрийн цахилгаан сэргээгдэх эрчим хүчний эх үүсвэртэй юу?",
      answers: [
         { answerText: "Тийм", isCorrect: true },
         { answerText: "Үгүй", isCorrect: true },
      ],
      isSingle: true
   },
   {
      question: "Та гэрийнхээ цахилгааны төлбөрт сард дунджаар хэдэн төгрөг төлдөг вэ?",
      answers: [
         { answerText: "₮30,000-50,000", isCorrect: true},
         { answerText: "₮50,000-70,000", isCorrect: true},
         { answerText: "₮70,000-100,000", isCorrect: true},
         { answerText: "₮100,000 - дээш", isCorrect: true}
      ],
      isSingle: true
   },
   {
      question: "Та гэртээ хэдүүлээ амьдардаг вэ?",
      answers: [
         { answerText: "Гэртээ … амьдардаг (тоо нэмэх)", isCorrect: true, isNumber: true },
      ]
   },

]

export const randQustions = questions